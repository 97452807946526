import cookie from '@/lib/cookie.js';
import { courseApi } from '@/api/course';
import { COURSE_SALE_TYPE_ENUM } from '@/constant/course';
import { Spin } from 'view-design';

export const state = () => ({
  queryForm: {
    // 试听状态
    auditionFlag: null,
    // 课程属性
    courseAttributeTypeList: [],
    // 课程类型
    courseType: null,
    // 专业id
    majorId: null,
    // 最高价格
    maxCoursePrice: null,
    // 最低价格
    minCoursePrice: null,
    // 售卖类型
    saleTypeList: [],
    // 搜索词
    searchWord: null,
    // 排序字段
    sortItemList: [],
    pageNum: 1,
    pageSize: 20
  },
  // 搜索结果
  courseList: [],
  // 搜索结果数量
  courseCount: 0,
  // 分页信息
  pageInfo: {
    pageNum: 1,
    pageSize: 20,
    pageTotal: 0
  }
});
export const getters = {};
export const mutations = {

  /**
   * 清空查询参数
   * @param state
   * @constructor
   */
  CLEAR_QUERY_FORM (state) {
    const majorId = state.queryForm.majorId;
    state.queryForm = {
      // 试听状态
      auditionFlag: null,
      // 课程属性
      courseAttributeTypeList: [],
      // 课程类型
      courseType: null,
      // 专业id
      majorId,
      // 最高价格
      maxCoursePrice: null,
      // 最低价格
      minCoursePrice: null,
      // 售卖类型
      saleTypeList: [],
      // 搜索词
      searchWord: null,
      // 排序字段
      sortItemList: [],
      pageNum: 1,
      pageSize: 20
    };
  },

  // 设置专业信息
  SET_MAJOR (state, data) {
    state.queryForm.majorId = data;
    state.queryForm.pageNum = 1;
  },
  // 设置优惠券信息
  SET_COUPON (state, data) {
    state.queryForm.couponId = data;
  },
  // ------------------------ 设置 全部/免费课/付费课/有试听 begin ------------------------
  // 全部
  SET_QUERY_ALL (state) {
    state.queryForm.auditionFlag = null;
    state.queryForm.saleTypeList = [];
    state.queryForm.pageNum = 1;
  },
  // 免费
  SET_QUERY_FREE (state) {
    state.queryForm.auditionFlag = null;
    state.queryForm.saleTypeList = [
      COURSE_SALE_TYPE_ENUM.COURSE_FRANCO.value,
      COURSE_SALE_TYPE_ENUM.COURSE_RECEIVE.value
    ];
    state.queryForm.pageNum = 1;
  },

  // 付费
  SET_QUERY_PAY (state) {
    state.queryForm.auditionFlag = null;
    state.queryForm.saleTypeList = [
      COURSE_SALE_TYPE_ENUM.COURSE_CHARGE.value
    ];
    state.queryForm.pageNum = 1;
  },
  // 试听
  SET_QUERY_AUDITION (state) {
    state.queryForm.auditionFlag = true;
    state.queryForm.saleTypeList = [];
    state.queryForm.pageNum = 1;
  },
  // ------------------------ 设置 全部/免费课/付费课/有试听 end ------------------------

  // 设置搜索关键字
  SET_SEARCH_WORD (state, data) {
    state.queryForm.searchWord = data;
    state.queryForm.pageNum = 1;
  },

  // 设置课程属性
  SET_COURSE_ATTRIBUTE (state, data) {
    state.queryForm.courseAttributeTypeList = [...data];
    state.queryForm.pageNum = 1;
  },

  // 课程类型
  SET_COURSE_TYPE (state, data) {
    state.queryForm.courseType = data;
    state.queryForm.pageNum = 1;
  },

  // 设置销量排序
  SET_ENROLLMENT_SORT (state, data) {
    if (data === null) {
      state.queryForm.sortItemList = [];
    } else {
      state.queryForm.sortItemList = [Object.assign({}, data)];
    }
    state.queryForm.pageNum = 1;
  },

  // 设置价格排序
  SET_PRICE_SORT (state, data) {
    if (data === null) {
      state.queryForm.sortItemList = [];
    } else {
      state.queryForm.sortItemList = [Object.assign({}, data)];
    }
    state.queryForm.pageNum = 1;
  },

  // 设置价格
  SET_PRICE (state, data) {
    state.queryForm.maxCoursePrice = data.value.maxCoursePrice;
    state.queryForm.minCoursePrice = data.value.minCoursePrice;
    state.queryForm.pageNum = 1;
  },

  // 搜索结果
  SET_COURSE_LIST (state, data) {
    state.courseList = data.list;
    state.courseCount = data.total;
    state.pageInfo.pageNum = data.pageNum;
    state.pageInfo.pageSize = data.pageSize;
    state.pageInfo.pageTotal = data.total;
  },

  // 设置分页参数
  SET_QUERY_PAGE_PARAM (state, data) {
    state.queryForm.pageNum = data.pageNum;
    state.queryForm.pageSize = data.pageSize;
  }

};

export const actions = {

  // 查询
  async QUERY_COURSE ({ commit, state }) {
    Spin.show();
    try {
      const res = await courseApi.queryList(state.queryForm);
      commit('SET_COURSE_LIST', res.data);
    } catch (e) {
      $nuxt.$smartSentry.captureException(e);
    }
    Spin.hide();
  }

};
