<!--
 * @Description: 验证码登录
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-26 08:44:38
 * @LastEditors: 康蔚明
 * @LastEditTime: 2020-09-30 09:47:14
-->
<template>
  <div>
    <div class="form">
      <div class="form-row" :class="phoneError?'error':''">
        <img src="/images/index/input-icon/input-phone-icon.png" />
        <div class="input-content">
          <Input
              v-model.trim="formData.phone"
              placeholder="请输入手机号码"
              maxlength="11"
          />
        </div>
      </div>
      <div class="validate-msg">{{phoneError}}</div>
      <div class="form-row sms"  :class="codeError?'error':''">
        <img src="/images/index/input-icon/input-sms-icon.png" />
        <div class="input-content">
          <Input
              v-model.trim="formData.smsCode"
              placeholder="请输入验证码"
              maxlength="6"
          />
        </div>
        <div
            class="get-sms"
            :class="{disable: disableGetBut}"
            @click="sendVerify"
        >
          {{ getSmsTips }}
        </div>
      </div>
      <div class="validate-msg">{{codeError}}</div>
      <div
          class="send-button"
          @click="loginValidate"
      >
        登 录
      </div>
    </div>
    <Spin
        v-if="isLoading"
        fix
    >
      <Icon
          type="ios-loading"
          size="36"
          class="demo-spin-icon-load"
      />
    </Spin>
  </div>
</template>

<script>
import { accountApi } from '@/api/account';
import { commonApi } from '@/api/common';
import { regular } from '@/lib/regular';
import { SMS_VERIFY_ACTION_TYPE_ENUM, SMS_VERIFY_TYPE_ENUM } from '@/constant/common';
import { ACCOUNT_SHOW_TYPE, DEVICE_TYPE_ENUM, TERMINAL_APP_TYPE_ENUM } from '@/constant/account';

export default {
  name: 'AccountLoginSms',
  components: {},
  filters: {},
  props: {},
  data () {
    return {
      formData: {
        phone: null,
        smsCode: null,
        deviceType: DEVICE_TYPE_ENUM.PC.value,
        terminalAppType: TERMINAL_APP_TYPE_ENUM.USER_PC.value
      },
      isLoading: false,
      getSmsTips: '获取验证码',
      disableGetBut: false,
      countDownTimer: null, // 定时器
      phoneError: '',
      codeError: ''
    };
  },
  computed: {},
  watch: {
    'formData.phone' (val) {
      this.phoneError = '';
      if (!this.formData.phone) {
        this.phoneError = '请输入手机号码';
        return;
      }
      if (!regular.phone.test(this.formData.phone)) {
        this.phoneError = '手机号码不正确';
      }
    },
    'formData.smsCode' (val) {
      this.codeError = '';
      if (!this.formData.smsCode) {
        this.codeError = '请输入验证码';
      }
    }
  },
  created () {
  },
  mounted () {
  },
  beforeDestroy () {
    this.resetParams();
    this.unListenEnterKeyDown();
  },
  methods: {

    // 监听回车按下事件
    listenEnterKeyDown () {
      document.onkeydown = (e) => {
        const event = e || event;
        if (event.keyCode == 13) {
          event.preventDefault();
          this.loginValidate();
        }
      };
    },
    // 撤销监听回车按下事件
    unListenEnterKeyDown () {
      document.onkeydown = undefined;
    },
    // 切换到密码登录
    changeLoginType () {
      this.$store.commit('user/changeAccountType', ACCOUNT_SHOW_TYPE.LOGIN_PASS.value);
    },

    // 发送验证码
    async sendVerify () {
      if (this.disableGetBut) {
        return;
      }
      try {
        this.isLoading = true;
        if (!this.formData.phone) {
          this.phoneError = '请输入手机号码';
          this.$Message.error('请输入手机号码');
          return;
        }
        if (!regular.phone.test(this.formData.phone)) {
          this.codeError = '手机号码不正确';
          this.$Message.error('手机号码不正确');
          return;
        }
        const data = {
          phone: this.formData.phone,
          actionType: SMS_VERIFY_ACTION_TYPE_ENUM.SMS_VERIFY_LOGIN.value,
          verifyType: SMS_VERIFY_TYPE_ENUM.AUTHENTICATION.value
        };
        await commonApi.sendVerify(data);
        this.runCountDown();
        this.$Message.success('验证码已发送');
      } catch (err) {
        this.$smartSentry.captureException(err);
      } finally {
        this.isLoading = false;
      }
    },

    // 开始倒计时
    runCountDown () {
      this.disableGetBut = true;
      let countDown = 60;
      this.getSmsTips = `${countDown}秒重新获取`;
      this.countDownTimer = setInterval(() => {
        if (countDown > 1) {
          countDown--;
          this.getSmsTips = `${countDown}秒重新获取`;
        } else {
          clearInterval(this.countDownTimer);
          this.disableGetBut = false;
          this.getSmsTips = '获取验证码';
        }
      }, 1000);
    },

    // 点击登录，验证登录信息
    loginValidate () {
      if (!this.formData.phone) {
        this.phoneError = '请输入手机号码';
        return;
      }
      if (!regular.phone.test(this.formData.phone)) {
        this.phoneError = '手机号码不正确';
        return;
      }
      if (!this.formData.smsCode) {
        this.codeError = '请输入验证码';
        return;
      }
      this.loginBuySms();
    },

    // 登录api
    async loginBuySms () {
      try {
        this.isLoading = true;
        const result = await accountApi.loginBySms(this.formData);
        this.$emit('loginSuccess', result.data);
        this.$Message.success('登录成功');
      } catch (err) {
        this.$smartSentry.captureException(err);
      } finally {
        this.isLoading = false;
      }
    },

    // 重置数据
    resetParams () {
      clearInterval(this.countDownTimer);
      Object.assign(this.$data, this.$options.data.call(this));
    }

  }
};
</script>
<style lang='less' scoped>
@import "./account.less";
</style>
