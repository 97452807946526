/*
 * @Description:
 * @version:
 * @Author: 李云飞
 * @Date: 2020-08-25 17:25:47
 * @LastEditors: hanyu
 * @LastEditTime: 2021-03-11 13:50:01
 */
import { postAxios, getAxios } from '@/lib/http';
// import Cookies from '@/lib/cookie';
export const commonApi = {
  // 查询展示图 by lianmin
  queryBanner (data) {
    return postAxios('/common/pic', data);
  },

  // 发送【验证码】 by listen
  sendVerify (data) {
    return getAxios(`/base/sms/verify/send/${data.phone}/${data.verifyType}`);
  },

  // 获取图形验证码 @listen
  getCaptcha () {
    return getAxios('/base/captcha');
  },
  // 意见反馈-新增 @author zhuo
  addFeedback (data) {
    return postAxios('/pc/feedback/add', data);
  },

  // 系统信息-查询 @lxd
  getSystemInfo () {
    return getAxios('/pc/systemInfo/query');
  },
  // PC配置-查询 @lxd
  getSystemInfoPic () {
    return getAxios('/pc/systemInfo/pc/query');
  }

};
