import { postAxios, getAxios } from '@/lib/http';

export const buryPointApi = {
  // 上报埋点信息 by yandy
  reportBuryPoint: (data) => {
    return postAxios('/pc/bury/point/report', data);
  },
  reportBuryPointUrl: () => {
    return '/pc/bury/point/report';
  },
  beaconReportBuryPointUrl: () => {
    return '/pc/bury/point/beacon/report';
  }
};
