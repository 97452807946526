<template>
  <div class="header">
    <!-- <div class="header-top">
      <div class="top-center">
        <p class="item-header">
          <img src="https://beipaijingu.oss-cn-qingdao.aliyuncs.com/static/pc/common/phone-38x38.png"/>
          客服电话:{{
            this.$store.state.user.systemInfo.customerServicePhone
          }}
        </p>
        <p>
          工作时间：周一至周五
          {{ this.$store.state.user.systemInfo.workStartTime }}-{{ this.$store.state.user.systemInfo.workEndTime }}
          周末节假日不服务
        </p>
      </div>
    </div> -->
    <div class="header-container">
      <div class="content">
        <div class="logo">
          <img :src="logo" @click="goHome">
          <span>中国民间中医医药研究开发协会<br>北派筋骨辩证手法分会</span>
        </div>
        <div class="right-area">
          <img :src="qrCode"/>
          <div class="text">关注微信<br>联系客服</div>
        </div>
      </div>
    </div>
    <div class="nav-bar">
      <div class="nav-center">
        <div class="nav-item"
          v-for="item in navList"
          :key="item.path"
          :class="{'active-item': item.path == $route.fullPath}"
          @click="navItemClick(item)"
          >{{item.title}}</div>
      </div>
    </div>
    <LoginModal ref="loginModalRef"/>
  </div>
</template>
<script>
import LoginModal from '@/components/account/components/login-modal.vue';
import { newsApi } from '@/api/news';

export default {
  name: '',
  components: {
    LoginModal
  },
  filters: {},
  mixins: [],
  props: {},
  async asyncData({ app, store }) {
    // 公告
    // 快讯
    const noticeResult = await newsApi.queryNewsList({ pageNum: 1, pageSize: 5 });
    const noticeList = noticeResult.data.list;
    return {
      noticeList
    };
  },
  data() {
    return {
    };
  },
  computed: {
    navList() {
      return [
        { title: '首页', path: '/', pcCenterFlag: false, needLogin: false },
        { title: '行业信息', path: '/news/news-list?type=1002', pcCenterFlag: false},
        { title: '政策法规', path: '/news/news-list?type=1006', pcCenterFlag: false},
        { title: '常见问题', path: '/news/news-list?type=1011', pcCenterFlag: false},
        { title: '课程学习', path: 'center/sign', pcCenterFlag: true, needLogin: true },
        { title: '题库练习', path: 'center/my-question-bank', pcCenterFlag: true, needLogin: true},
        { title: '在线考试', path: 'center/my-question', pcCenterFlag: true, needLogin: true },
        { title: '证书申请', path: 'center/certificate', pcCenterFlag: true, needLogin: true }
      ]
    },
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    bgImg() {
      return this.$store.state.user.systemPic.topBgImg;
    },
    bgImgStyle() {
      if (this.bgImg) {
        return {
          backgroundImage: `url(${this.bgImg})`
        };
      } else {
        return {};
      }
    },
    logo() {
      return this.$store.state.user.systemInfo.logo;
    },
    qrCode() {
      return this.$store.state.user.systemInfo.followQrCode
    }
  },
  watch: {
  },
  created() {
  },
  mounted() {
    this.$store.dispatch('user/getSystemInfo');
    this.$store.dispatch('user/getSystemPic');
    console.log(this.$route)
  },
  beforeCreate() {
  },
  beforeMount() {
  },
  beforeUpdate() {
  },
  updated() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  activated() {
  },
  methods: {
    goHome() {
      this.$router.push({
        path: '/'
      });
    },
    navItemClick(item) {
      console.log('click-------')
      if (item.path == this.$route.fullPath) return;
      if (item.needLogin && this.lodash.isEmpty(this.userInfo)) {
        if (this.$route.path == '/') {
          this.$emit('login');
        } else {
          this.$refs.loginModalRef.showModal();
        }
        return;
      }
      if (item.pcCenterFlag) {
        this.$pcRouter.push({path: item.path});
      } else {
        console.log('跳转---------')
        this.$router.push({path: item.path});
        
      }
    }
  }
};
</script>
<style lang="less" scoped>
.header {
  background: #fff;
  .header-container {
    width: 100%;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
      width: 1200px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #000000;

      .logo {
        display: flex;
        align-items: center;
        color: #000000;

        > img {
          width: 100px;
          height: 100px;
        }

        > span {
          margin-left: 28px;
          display: inline-block;
          text-align: center;
          font-size: 28px;
          line-height: 38px;
          font-weight: 700;
          color: #333;
        }
      }
      .right-area {
        width: 200px;
        height: 86px;
        border: 1px solid #ededed;
        border-radius: 4px;
        box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.10); 
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        img {
          width: 76px;
          height: 76px;

        }
        div {
          line-height: 25px;
          font-size: 18px;
          color: #1c2634;
        }
      }
    }
  }
  .nav-bar {
    width: 100%;
    height: 54px;
    background: #13619f;
    display: flex;
    justify-content: center;
    .nav-center {
      width: 1200px;
      height: 54px;
      display: flex;
      .nav-item {
        height: 54px;
        line-height: 54px;
        cursor: pointer;
        font-size: 16px;
        color: #fff;
        padding: 0 40px;
      }
      .active-item {
        background: #0f5287;
        font-weight: 700;
      }
    }
  }
}
</style>
