/*
 * @Author: 马明昌 m17319036829@163.com
 * @Date: 2024-10-23 17:23:52
 * @LastEditors: 马明昌 m17319036829@163.com
 * @LastEditTime: 2024-10-24 16:59:36
 * @FilePath: \beipaijingu\pc\pc-center-web\src\constants\certificate.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 审核状态
export const CERTIFICATE_APPLY_AUDIT_STATUS_ENUM = {
  CHECKING: {
    value: 0,
    desc: '待审核'
  },
  COMPLETE: {
    value: 1,
    desc: '已审核通过'
  },
  REJECT: {
    value: 2,
    desc: '已驳回'
  }
};

// 发证状态
export const ISSUING_CERTIFICATE_STATUS_ENUM = {
  CHECKING: {
    value: 0,
    desc: '待发证'
  },
  COMPLETE:{
    value: 1,
    desc: '已发证'
  }
};

// 证书类型
export const CERTIFICATE_TYPE_ENUM = {
  ZHONG_YI_TU_SHOU_KANG_FU_TIAO_LI_JI_SHU: {
    value: 1,
    desc: '中医徒手康复技术'
  },
  ZHONG_YI_SHAN_SHI_YANG_SHENG_TIAO_LI_JI_SHU: {
    value: 2,
    desc: '中医膳食养生调理技术'
  },
  ZHONG_YI_ZHEN_JIU_ZHI_LIAO__JI_SHU: {
    value: 3,
    desc: '中医针灸治疗技术'
  },
  ZHONG_YI_FANG_YAO_ZHI_LIAO_JI_SHU: {
    value: 4,
    desc: '中医方药治疗技术'
  }
};

// 级别
export const CERTIFICATE_LEVEL_ENUM = {
  BEGINNER: {
    value: 1,
    desc: '初级'
  },
  INTERMEDIATE: {
    value: 2,
    desc: '中级'
  },
  ADVANCED: {
    value: 3,
    desc: '高级'
  }
};

export const CATEGORY_TYPE_ENUM = {
  ZHONG_YI_KANG_FU_LI_LIAO_JI_SHU: {
    value: 900,
    desc: '中医康复理疗技术'
  },
  ZHONG_YI_YAO_SHAN_YANG_SHENG_JI_SHU: {
    value: 901,
    desc: '中医药膳养生技术'
  },
  ZHONG_YI_ZHEN_JIU_JI_SHU: {
    value: 1000,
    desc: '中医针灸技术'
  },
  ZHONG_YI_NEI_FU_FANG_YAO_JI_SHU: {
    value: 1001,
    desc: '中医内服方药技术'
  }
};

export default {
  ISSUING_CERTIFICATE_STATUS_ENUM,
  CERTIFICATE_APPLY_AUDIT_STATUS_ENUM,
  CERTIFICATE_TYPE_ENUM,
  CERTIFICATE_LEVEL_ENUM,
  CATEGORY_TYPE_ENUM
};
