/*
 * @Description: 浏览器校验
 * @Author: lihaifan
 * @Date: 2021/7/3 10:33
 */
export default function (context) {
  context.userAgent = process.server ? context.req.headers['user-agent'] : navigator.userAgent;
  const isEdge = context.userAgent.includes('Edge') || context.userAgent.includes('Edg'); // 判断是否IE的Edge浏览器
  const isSafari = context.userAgent.includes('Safari') && !context.userAgent.includes('Chrome'); // 判断是否Safari浏览器
  const isChrome = context.userAgent.includes('Chrome') && context.userAgent.includes('Safari') && !isEdge; // 判断Chrome浏览器
  if (!isEdge && !isChrome && !isSafari) {
    context.store.commit('browser/setBrowserValidModal', true);
  }
}
