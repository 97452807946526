<!--
 * @Description: 空白的layout，不用任何layout时使用
 * @version:
 * @Author: 李云飞
 * @Date: 2020-10-07 11:02:53
 * @LastEditors: hanyu
 * @LastEditTime: 2021-05-18 16:30:37
-->
<template>
  <div>
    <!-- 登录弹窗 -->
    <Account ref="accountRef"></Account>
    <!-- 浏览器校验弹窗 -->
    <BrowserValidModal></BrowserValidModal>
    <nuxt />
  </div>
</template>

<script>
import Account from '@/components/account';
import BrowserValidModal from '@/components/browser-valid-modal';
import buryPoint from '@/mixins/bury-point';

export default {
  name: 'EmptyLayout',
  components: { Account, BrowserValidModal },
  filters: {},
  mixins: [buryPoint],
  props: {},
  data () {
    return {};
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
  },
  beforeCreate () {
  },
  beforeMount () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  activated () {
  },
  methods: {}
};
</script>
<style lang='less' scoped>
</style>
