import { postAxios, getAxios } from '@/lib/http';

export const newsApi = {
  // 资讯-分页查询资讯 @author lidoudou
  queryNewsList: (data) => {
    return postAxios('/pc/news/list', data);
  },
  // 资讯-获取详情 @author zhuoda
  getNewsDetail: (newsId) => {
    return getAxios(`/pc/news/detail/${newsId}`);
  },

  // 资讯一级类型列表 @author yandy
  getAllTypeList: (data) => {
    return postAxios('/base/category/tree', data);
  },

  // 资讯二级类型列表 @author zhuo
  getChildrenTypeList: (parentId) => {
    return getAxios(`/pc/news/type/children/${parentId}`);
  }
};
