/*
 * @Author: 马明昌 m17319036829@163.com
 * @Date: 2024-09-09 08:52:16
 * @LastEditors: 马明昌 m17319036829@163.com
 * @LastEditTime: 2024-09-26 09:11:39
 * @FilePath: \bozhouzhiye-yixue-front\pc\pc-web\middleware\is-mobile.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { isPCBroswer } from '../lib/utils';

export default function (context) {
  context.userAgent = process.server ? context.req.headers['user-agent'] : navigator.userAgent;
  const path = context.route.path;
  const query = context.route.query;
  const isH5 = path.includes('/h5/') || query.h5Flag;
  if (isH5 && isPCBroswer(context.userAgent)) {
    context.redirect(301, '/');
  }
  if (!isH5 && /Android|webOS|iPhone|iPod|BlackBerry/i.test(context.userAgent)) {
    context.redirect(301, '/h5/');
  }
}
