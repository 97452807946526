import { TERMINAL_APP_TYPE_ENUM } from '@/constant/account';
import cookie from '@/lib/cookie';
import { buryPointApi } from '../api/bury-point';

export default {
  data () {
    return {};
  },
  mounted () {
    // 监听页面刷新或关闭 参数二Fucntion 不能写()
    window.addEventListener('beforeunload', this.beforeunloadSendBuryPoint, false);
    const params = this.$route.query.params;
    if (params) {
      this.$buryPoint('从宣教平台进入自动登录', `当前页面：${location.href}`, JSON.stringify(this.$route.query));
    } else {
      this.$buryPoint('用户进入NUXT页面', `当前页面：${location.href}`, JSON.stringify(this.$route.query));
    }
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  activated () {
  },
  methods: {
    beforeunloadSendBuryPoint () {
      const data = {
        title: '用户离开NUXT页面',
        content: `跳出页面：${location.href}`,
        extData: JSON.stringify(this.$route.query),
        terminalType: TERMINAL_APP_TYPE_ENUM.USER_PC.value
      };
      const sendParams = JSON.stringify(data);
      const sendRecordUrl = `${process.env.baseUrl}${buryPointApi.beaconReportBuryPointUrl()}?x-access-token=${cookie.getToken()}`;
      // if (navigator.sendBeacon) {
      //   navigator.sendBeacon(sendRecordUrl, sendParams);
      //   return;
      // }
      // const xhr = new XMLHttpRequest();
      // xhr.open('POST', sendRecordUrl, false);
      // xhr.setRequestHeader('Content-Type', 'text/plain;charset=utf-8');
      // xhr.send(sendParams);
    }
  }
};
