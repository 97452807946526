/*
 * @Description: 工具库
 * @Author: hanyu
 * @Date: 2020-03-16 21:48:28
 * @LastEditTime: 2020-12-31 11:12:55
 * @LastEditors: hanyu
 */

/**
 * 复制文本
 * @param {*} text
 */
export const copyContent = (text) => {
  let target = null;
  target = document.createElement('div');
  target.id = 'tempTarget';
  target.style.opacity = '0';
  // eslint-disable-next-line unicorn/prefer-text-content
  target.innerText = text;
  document.body.appendChild(target);
  try {
    const range = document.createRange();
    range.selectNode(target);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    target.parentElement.removeChild(target);
    return true;
  } catch (e) {
    target.parentElement.removeChild(target);
    return false;
  }
};

/**
 * 判断某个原生DOM元素是否不在屏幕可见区内
 * @param {*} el 原生DOM元素
 */
export const isElementNotInViewport = (el) => {
  if (el) {
    return;
  }
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= (window.innerHeight || document.documentElement.clientHeight) ||
        rect.bottom <= 0
  );
};

/**
 * 计算剩余时间  剩余x 天 x 小时 x 分钟 x 秒
 * @param {*} end  结束时间
 */
export const getResidualTime = (end_time) => {
  const now_time = new Date().getTime();
  const resetSec = end_time - now_time;
  const day = parseInt(resetSec / (60 * 60 * 24 * 1000));
  const hour = parseInt(resetSec / (60 * 60 * 1000) % 24);
  const minute = parseInt(resetSec / (60 * 1000) % 60);
  const sec = parseInt(resetSec / 1000 % 60);
  return {
    day,
    hour,
    minute,
    sec
  };
};
export const utils = {
  // 获取url 中的key
  getUrlKey (name) {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(window.location.href) || [, ''])[1].replace(/\+/g, '%20')) || null;
  }
};

// 获取ref下直接子元素的合计宽度
export const getElWidth = (refEl) => {
  const children = refEl.children;
  let totalWidth = 0;
  for (let i = 0; i < children.length; i++) {
    totalWidth += children[i].offsetWidth;
  }
  return totalWidth + 1 + 'px';
};

// 日期格式
export const dateFormat = {
  YMD: 'YMD',
  YMDHM: 'YMDHM',
  YMDHMS: 'YMDHMS',
  HMS: 'HMS',
  HM: 'HM'
};

// 获取日期字符串，如：2020-05-21 15:33:02
export const getDate = (date, format, dateSeparator) => {
  if (!dateSeparator) {
    dateSeparator = '-';
  }
  const year = date.getFullYear();
  // day获取当前几号，不足10补0
  const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
  // month获取当前月份的日期，不足10补0
  const month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : '0' + (date.getMonth() + 1);
    // h获取当前小时，不足10补0
  const h = date.getHours() > 9 ? date.getHours() : '0' + date.getHours();
  // s获取当前分钟，不足10补0
  const m = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes();
  // s获取当前秒数，不足10补0
  const s = date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds();
  let resultDate = '';
  if (format === dateFormat.YMD) {
    resultDate = year + dateSeparator + month + dateSeparator + day;
  }
  if (format === dateFormat.YMDHM) {
    resultDate = year + dateSeparator + month + dateSeparator + day + ' ' + h + ':' + m;
  }
  if (format === dateFormat.YMDHMS) {
    resultDate = year + dateSeparator + month + dateSeparator + day + ' ' + h + ':' + m + ':' + s;
  }
  if (format === dateFormat.HMS) {
    resultDate = h + ':' + m + ':' + s;
  }
  if (format === dateFormat.HM) {
    resultDate = h + ':' + m;
  }
  return resultDate;
};

// 获取当前日期getDateStr(0)、前几天getDateStr(-10)、后几天getDateStr(20)
export const getDateStr = (AddDayCount, format) => {
  const date = new Date();
  // 获取AddDayCount天后的日期
  date.setDate(date.getDate() + AddDayCount);
  return getDate(date, format);
};

/**
 * 将秒转换为时分秒
 */
export const secondToDate = (second) => {
  const h = Math.floor(second / 3600) < 10 ? '0' + Math.floor(second / 3600) : Math.floor(second / 3600);
  const m = Math.floor((second / 60 % 60)) < 10 ? '0' + Math.floor((second / 60 % 60)) : Math.floor((second / 60 % 60));
  const s = Math.floor((second % 60)) < 10 ? '0' + Math.floor((second % 60)) : Math.floor((second % 60));
  return second < 3600 ? `${m}:${s}` : `${h}:${m}:${s}`;
};

/**
 * 获取操作系统
 */
export const getSystemName = (userAgent) => {
  let systemName = '';
  if (userAgent.includes('iPhone')) {
    systemName = 'iPhone';
  } else if (userAgent.includes('Android')) {
    systemName = 'Android';
  } else if (userAgent.includes('Windows NT 10.0')) {
    systemName = 'Windows 10';
  } else if (userAgent.includes('Windows NT 6.2')) {
    systemName = 'Windows 8';
  } else if (userAgent.includes('Windows NT 6.1')) {
    systemName = 'Windows 7';
  } else if (userAgent.includes('Windows NT 6.0')) {
    systemName = 'Windows Vista';
  } else if (userAgent.includes('Windows NT 5.1')) {
    systemName = 'Windows XP';
  } else if (userAgent.includes('Windows NT 5.0')) {
    systemName = 'Windows 2000';
  } else if (userAgent.includes('Mac')) {
    systemName = 'Mac';
  } else if (userAgent.includes('X11')) {
    systemName = 'UNIX';
  } else if (userAgent.includes('Linux')) {
    systemName = 'Linux';
  } else {
    systemName = 'other';
  }
  return systemName;
};

/**
 * 获取浏览器名称
 */
export const getBrowserName = (userAgent) => {
  // 需要匹配的浏览器名称
  const browserNameList = ['MicroMessenger', 'QQBrowser', 'UCBrowser', 'Edge', 'OPR', 'Vivaldi', 'Firefox', 'Chrome', 'Safari'];
  const regexp = browserNameList.map(name => new RegExp(`${name}\\/(\\d+\\.)+\\d+`));
  // 过滤浏览器信息
  let browser = userAgent.match(/[a-z]+\/(\d+\.)+\d+/ig).filter(_ => regexp.findIndex(v => v.test(_)) !== -1);
  // 如果最后一项不是Safari并且结果长度大于1取最后一项为当前浏览器信息
  browser = browser.length > 1 && !browser[browser.length - 1].startsWith('Safari') ? browser.reverse() : browser;

  if (!browser) {
    return '';
  }

  return browser[0];
};

// 将英文转换为中文
export const setEnIndexToChIndex = (index) => {
  switch (index) {
    case 1:
      return '一';
    case 2:
      return '二';
    case 3:
      return '三';
    case 4:
      return '四';
    case 5:
      return '五';
    case 6:
      return '六';
    case 7:
      return '七';
    case 8:
      return '八';
    case 9:
      return '九';
    case 10:
      return '十';
  }
};

/**
 * 根据区id获取默认地区所属id数组
 * @param {Number} 传入区的id，如140122，返回[140000,140100,140122]
 */
export const getDefaultAreaArray = (districtId) => {
  const areaString = districtId.toString();
  const defaultAreaArray = [
    Number(areaString.substring(0, 2) + '0000'),
    Number(areaString.substring(0, 4) + '00'),
    districtId
  ];
  return defaultAreaArray;
};

// 判断是否为微信浏览器
export const isWxChatBrowser = () => {
  const ua = window.navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true;
  } else {
    return false;
  }
};

/**
 *  @description 检测是否为pc端
 */
export const isPCBroswer = (userAgent) => {
  if (!userAgent) {
    userAgent = window.navigator.userAgent;
  }
  if (
    userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
  ) {
    return false;
  } else {
    return true;
  }
};

// 获取url 中的key
export const getUrlKey = (name) => {
  return (
    decodeURIComponent(
      (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
        window.location.href
      ) || [, ''])[1].replace(/\+/g, '%20')
    ) || null
  );
};

/**
  * 获取随机字符串
  * ${length} 长度
  */
export function getRandomChars(length) {
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let result = '';
  while (result.length < length) {
    const index = Math.floor(Math.random() * chars.length);
    const char = chars.substring(index, index + 1);
    result += char;
  }
  return result;
}
