<!--
 * @Description: 首页 全部课程 等导航栏
 * @Author: hanyu
 * @Date: 2020-09-20 16:21:21
 * @LastEditTime: 2021-03-11 11:34:09
 * @LastEditors: hanyu
-->
<template>
    <div class="main-menu-box">
        <a v-for="item in routeData" :key="item.id" class="item-menu" :class="{ active: checkActive(item) }" @click="onTab(item)">
            {{ item.name }}
        </a>
    </div>
</template>

<script>
export default {
  data () {
    return {
      routeData: [
        {
          name: '首页',
          routeName: 'home',
          path: '/',
          id: 0
        },
        // {
        //   name: '全部课程',
        //   routeName: 'course',
        //   path: '/course',
        //   id: 1
        // },
        // {
        //   name: '题库',
        //   path: 'center/my-question',
        //   routeName: 'my-question',
        //   id: 2,
        //   pcRoute: true, // 是否走 person-center 路由
        // },
        // {
        //   name: '图书商城',
        //   routeName: 'book',
        //   path: '/book',
        //   id: 7
        // },
        // {
        //   name: '师资',
        //   path: '/teacher',
        //   routeName: 'teacher',
        //   id: 3
        // },
        // {
        //   name: '资讯',
        //   path: '/news/news-list',
        //   routeName: 'news',
        //   id: 4
        // },
        // {
        //   name: '下载',
        //   path: '/app-download',
        //   routeName: 'app-download',
        //   id: 5
        // },
        // {
        //   name: '关于我们',
        //   path: '/about',
        //   routeName: 'about',
        //   id: 6
        // }
      ]

    };
  },
  computed: {
    checkActive () {
      return function (item) {
        let routeIncludes = this.$route.name.includes(item.routeName);
        if (item.routeName === 'in-development') {
          const id = Number(this.$route.query.id || -1);
          routeIncludes = id === item.id;
        }
        return routeIncludes;
      };
    }
  },
  mounted () {
    console.log(this.$route.name);
  },
  methods: {
    // 跳转
    onTab (item) {
      if(item.pcRoute){
        this.$pcRouter.push({ path: item.path });
        return
      }
      this.$store.commit('setTabId', item.id);
      const routeData = this.$router.resolve({
        path: item.path,
        query: item.routeName === 'in-development' ? {
          id: item.id
        } : {}
      });
      window.open(routeData.href, '_blank');
    }
  }
};
</script>
<style lang="less" scoped>
.main-menu-box {
    display: flex;
    align-items: center;
    font-size: 16px;

    .item-menu {
        margin-left: 30px;
        color: #212121;

        &:hover {
            color: @main-color;
        }
    }

    .active {
        color: @main-color;
    }
}
</style>
