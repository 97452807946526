<!--
 * @Description: 顶部最右侧用户信息
 * @Author: hanyu
 * @Date: 2020-09-20 16:21:21
 * @LastEditTime: 2020-10-22 15:33:18
 * @LastEditors: hanyu
-->
<template>
  <div class="user-option">
    <a v-if="userInfo" class="user-left" @click="openPc('learn-center/sign')">
      学习中心
    </a>
    <div class="user-right" @mouseover="show = true" @mouseleave="show = false">
      <Dropdown transfer>
        <div v-if="userInfo" class="user-box">
          <Avatar
            class="header"
            :src="userInfo ? userInfo.avatar : userHeader"
          />
          <Icon type="ios-arrow-down" color="#999999" class="allow" :class="{active : show}" size="20" />
        </div>
        <div v-else class="no-login">
          <a class="login" @click="openLogin">登录</a>
<!--          <span>|</span>-->
<!--          <a class="register" @click="openRegister">注册</a>-->
          <!-- <a class="login-txt" @click="openLogin">请登录</a> -->
        </div>
        <DropdownMenu v-if="userInfo" slot="list">
          <!--<DropdownItem>
            <p
              @click="openPc('learn-center',{userCenterTabValue:'UserInfo'})"
            >
              个人中心
            </p>
          </DropdownItem>
          <DropdownItem>
            <p
              @click="openPc('learn-center/my-course')"
            >
              我的课程
            </p>
          </DropdownItem>
          <DropdownItem>
            <p
              @click="openPc('my-order',{userCenterTabValue:'MyOrder'})"
            >
              我的订单
            </p>
          </DropdownItem>-->
          <DropdownItem>
            <p
              href="javascript:void(0)"
              @click="logout"
            >
              退出登录
            </p>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  </div>
</template>

<script>
import { ACCOUNT_SHOW_TYPE } from '@/constant/account';
import userHeader from '@/assets/image/index/user-header.png';

export default {
  components: {},
  data () {
    return {
      show: false,
      showOption: false,
      userHeader

    };
  },
  computed: {
    userInfo () {
      return this.$store.state.user.userInfo;
    }
  },
  methods: {
    // 点击登录
    openLogin () {
      const params = {
        isShow: true,
        showType: ACCOUNT_SHOW_TYPE.LOGIN_PASS.value
      };
      this.$store.commit('user/changeAccountModal', params);
    },
    // 点击注册
    openRegister () {
      const params = {
        isShow: true,
        showType: ACCOUNT_SHOW_TYPE.REGISTER.value
      };
      this.$store.commit('user/changeAccountModal', params);
    },
    // 跳转PC页面
    openPc (path, query) {
      this.$pcRouter.push({ path, query });
    },
    // 退出登录
    logout () {
      this.$Modal.confirm({
        title: '提示',
        content: '是否确认退出登录？',
        onOk: () => {
          this.$store.commit('user/clearUserInfo');
        }
      });
    }
  }
};
</script>
<style lang='less' scoped>
.user-option {
  justify-self: end;
  display: flex;
  align-items: center;

  .user-left {
    color: #333333;
    font-size: 16px;
    padding-right: 15px;
    border-right: 1px dashed #ededed;
  }

  .user-right {
    position: relative;
    width: 90px;
    z-index: 1000;
    .user-box {
      display: flex;
      align-items: center;
      margin-left: 15px;
      cursor: pointer;

      .header {
        width: 36px;
        height: 36px;
        background: #eeeeee;
        border-radius: 100%;
      }

      .allow {
        margin-left: 5px;
        transition: all 0.2s ease-in-out;
        transform: rotate(0deg);
      }

      .active {
        transform: rotate(180deg);
      }
    }

    .no-login {
      display: flex;
      width: 300px;

      .login {
        padding-right: 15px;
      }

      .register {
        padding-left: 15px;
      }

      .login, .register {
        font-size: 16px;
        color: #333;
        &:hover {
          color: @main-color;
          border-color: @main-color;
        }
      }
    }

    .option-box {
      position: absolute;
      left: 0;
      right: 0;
      top: 35px;
      transition: all 0.2s ease-in-out;
      transform: scale(0);
      z-index: 10;
      padding-top: 10px;
      height: 0;
      opacity: 0;
      overflow: hidden;
      transform-origin: top;

      &.active {
        transform: scale(1);
        height: auto;
        opacity: 1;
      }

      .option-list {
        padding: 12px 18px;
        background: #ffffff;
        border: 1px solid #ededed;
        border-radius: 0px 0px 4px 4px;
        box-shadow: 0px 6px 10px 0px rgba(155, 155, 155, 0.16);
        font-size: 12px;
        color: #666666;

        li {
          cursor: pointer;
          margin-top: 13px;

          &:first-child {
            margin-top: 0;
          }

          &:hover {
            color: @main-color;
          }
        }
      }
    }
  }
}
</style>
