/*
 * @Description:
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-24 10:13:17
 * @LastEditors: hanyu
 * @LastEditTime: 2020-12-30 09:43:36
 */

//  登录弹窗显示类型
export const ACCOUNT_SHOW_TYPE = {
  LOGIN_PASS: {
    value: 1,
    desc: '密码登录'
  },
  LOGIN_SMS: {
    value: 2,
    desc: '验证码登录'
  },
  REGISTER: {
    value: 3,
    desc: '用户注册'
  },
  FORGET_PASS: {
    value: 4,
    desc: '忘记密码'
  }
};
export const TERMINAL_APP_TYPE_ENUM =
{
  USER_H5:{
    value:100,
    desc:'用户端-H5'
  },
  USER_APP_ANDROID:{
    value:110,
    desc:'用户端-安卓'
  },
  USER_APP_IOS:{
    value:120,
    desc:'用户端-苹果'
  },
  USER_PC:{
    value:130,
    desc:'用户端-PC'
  },
  ADMIN_PC:{
    value:200,
    desc:'管理端-PC'
  }
};
// 注册渠道
export const USER_REGISTER_CHANNEL_TYPE_ENUM =
{
  USER_H5:{
    value:100,
    desc:'用户端-H5'
  },
  USER_APP_ANDROID:{
    value:110,
    desc:'用户端-安卓'
  },
  USER_APP_IOS:{
    value:120,
    desc:'用户端-苹果'
  },
  USER_PC:{
    value:130,
    desc:'用户端-PC'
  },
  ADMIN_PC:{
    value:200,
    desc:'运营端-添加'
  }
};

export const
  USER_THIRD_TYPE_ENUM =
  {
    QQ: {
      value: 1,
      desc: 'QQ'
    },
    WE_CHAT: {
      value: 2,
      desc: '微信'
    },
    APPLE: {
      value: 3,
      desc: '苹果'
    }
  };

// 设备类型
export const DEVICE_TYPE_ENUM = {
  ANDROID: {
    value: 1,
    desc: 'android'
  },
  IOS: {
    value: 2,
    desc: 'ios'
  },
  PC: {
    value: 3,
    desc: 'PC'
  },
  UNKNOWN: {
    value: 44,
    desc: '未知'
  }
};
// 注册页面
export const USER_REGISTER_PAGE_TYPE_ENUM = {
  REGISTER_PAGE: {
    value: 1000,
    desc: '注册页面'
  }
};

export const USER_TYPE_ENUM = {
  ZHI_YE_YAO_SHI: { value: 1, desc: '执业药师' },
  YAO_SHI_XIE_LI: { value: 2, desc: '药师协理' },
  YAO_XUE_FEI_LIN_CHUANG: { value: 3, desc: '药学(非临床)' },
  OTHER: { value: 4, desc: '其他' }
};

export const USER_REGISTER_TYPE_ENUM = {
  ZHI_YE_YAO_SHI: { value: 1, desc: '执业药师' },
};

export default {
  ACCOUNT_SHOW_TYPE,
  TERMINAL_APP_TYPE_ENUM,
  USER_THIRD_TYPE_ENUM,
  DEVICE_TYPE_ENUM,
  USER_REGISTER_CHANNEL_TYPE_ENUM,
  USER_REGISTER_PAGE_TYPE_ENUM,
  USER_TYPE_ENUM,
  USER_REGISTER_TYPE_ENUM
};
