<!--
 * @Author: 马明昌 m17319036829@163.com
 * @Date: 2024-10-25 11:06:19
 * @LastEditors: 马明昌 m17319036829@163.com
 * @LastEditTime: 2024-10-25 11:15:43
 * @FilePath: \beipaijingu\pc\pc-web\components\account\components\login-modal.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <Modal v-model="show" class="" title="登录" width="376" footer-hide>
    <AccountContainer @success="loginSuccess"/>
  </Modal>
</template>
<script>
import AccountContainer from './account-container.vue';
export default {
  components: {
    AccountContainer
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    showModal() {
      this.show = true;
    },
    loginSuccess() {
      this.show = false;
    }
  }
}
</script>
