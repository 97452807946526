<template>
  <div class="customer" @click="openQimoChat">
    <div class="icon-area">
      <img src="@/assets/image/index/customer/customer-chat.png" />
    </div>
    <div class="text-area">
      <div class="text">
        和我们在线交谈!
      </div>
      <img src="@/assets/image/index/customer/customer-open.png"/>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  filters: {},
  created() {
  },
  mounted() {
  },
  beforeCreate() {
  },
  beforeMount() {
  },
  beforeUpdate() {
  },
  updated() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  activated() {
  },
  methods: {
    // 在线客服
    openQimoChat () {
      window.qimoChatClick();
    }
  }
};
</script>
<style lang="less" scoped>
.customer {
  width: 315px;
  height: 48px;
  background: #ffffff;
  border-top-left-radius: 8px;
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  cursor: pointer;
  z-index: 999;

  .icon-area {
    width: 54px;
    height: 48px;
    background: @main-color;
    border-top-left-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    >img{
      width: 26px;
      height: 26px;
    }
  }

  .text-area {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .text {
      margin-left: 30px;
      font-size: 16px;
      color: #666666;
    }

    > img {
      width: 16px;
      height: 8px;
      margin-right: 33px;
    }
  }
}
</style>