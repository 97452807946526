/*
 * @Description: 支付枚举
 * @Author: 康蔚明
 * @LastEditors: 康蔚明
 * @Date: 2020-09-26 15:58:18
 * @LastEditTime: 2020-09-29 08:58:24
 */
export const
GOODS_TYPE_ENUM =
{
  VIDEO:{
    value:1,
    desc:'视频课'
  },
  LIVE:{
    value:2,
    desc:'直播课'
  },
  COURSE_CLASS:{
    value:3,
    desc:'精品班'
  },
  OFFLINE_CLASS:{
    value:4,
    desc:'面授班'
  },
  COURSE_PLAN:{
    value:5,
    desc:'旗舰班'
  },
  EXAMINATION:{
    value:6,
    desc:'试卷'
  },
  QUESTION:{
    value:7,
    desc:'题库'
  },
  MALL_GOODS:{
    value:8,
    desc:'商城商品'
  }
}

export const
ORDER_TERMINAL_APP_TYPE_ENUM =
{
  PC:{
    value:1,
    desc:'PC'
  },
  APP_IOS:{
    value:2,
    desc:'IOS'
  },
  APP_ANDROID:{
    value:3,
    desc:'ANDROID'
  },
  H5:{
    value:4,
    desc:'H5'
  },
  WX_MP:{
    value:5,
    desc:'MP'
  }
}

// 微信支付状态
export const WX_PAY_TYPE = {
  NOT_PAY: {
    value: 0,
    desc: '未支付'
  },
  USER_PAYING: {
    value: 1,
    desc: '用户支付中'
  },
  SUCCESS: {
    value: 2,
    desc: '支付成功'
  },
  TIMEOUT: {
    value: 4,
    desc: '支付超时'
  }
};

export const
ALI_PAY_SCENE_ENUM =
{
  WEB:{
    value:'web',
    desc:'网页'
  },
  APP:{
    value:'app',
    desc:'APP移动端'
  },
  WAP:{
    value:'wap',
    desc:'手机网页'
  }
}

export const PAY_TYPE_ENUM = {
  WECHAT: {
    value: 1,
    desc: '微信'
  },
  BALANCE: {
    value: 3,
    desc: '余额'
  }
}

export const
ORDER_STAGE_ENUM =
{
  WAIT_PAY:{
    value:10,
    desc:'待支付'
  },
  WAIT_CONFIRM_RECEIVE_AMOUNT:{
    value:15,
    desc:'待确认收款'
  },
  PAID:{
    value:20,
    desc:'已支付'
  },
  WAIT_DELIVER:{
    value:30,
    desc:'待发货'
  },
  WAIT_RECEIVE:{
    value:40,
    desc:'待收货'
  },
  REFUND_IN_AUDIT:{
    value:50,
    desc:'退款审核中'
  },
  WAIT_SCHOOL_REFUND:{
    value:51,
    desc:'待分校退款'
  },
  REFUNDING:{
    value:60,
    desc:'退款中'
  },
  WAIT_EVALUATED:{
    value:80,
    desc:'待评价'
  },
  COMPLETE:{
    value:90,
    desc:'已完成'
  },
  CANCEL:{
    value:91,
    desc:'已取消'
  },
  REFUND_COMPLETE:{
    value:92,
    desc:'已退款'
  },
  ALREADY_CHANGE:{
    value:93,
    desc:'已换课'
  },
  END_COURSE:{
    value:94,
    desc:'已结课'
  },
  RETAKE_COURSE:{
    value:95,
    desc:'已重修'
  }
}

export default {
  GOODS_TYPE_ENUM,
  ORDER_TERMINAL_APP_TYPE_ENUM,
  WX_PAY_TYPE,
  ALI_PAY_SCENE_ENUM,
  PAY_TYPE_ENUM,
  ORDER_STAGE_ENUM
}
