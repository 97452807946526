/*
 * @Description:
 * @Author: hanyu
 * @Date: 2020-03-12 17:13:42
 * @LastEditTime: 2024-10-24 16:58:14
 * @LastEditors: 马明昌 m17319036829@163.com
 */
import common from './common';
import pagination from './pagination.js';
import course from './course';
import account from './account';
import pay from './pay';
import empty from './empty';
import book from './book';
import order from './order';
import distribution from './distribution';
import seo from './seo';
import certificate from './certificate';

export default {
  ...common,
  ...pagination,
  ...course,
  ...account,
  ...pay,
  ...empty,
  ...book,
  ...order,
  ...distribution,
  ...seo,
  ...certificate
};
