<!--
 * @Description: 首页浮窗
 * @Author: lihaifan
 * @Date: 2021/6/17 09:30
-->
<template>
  <div id="onlineService" class="online-service">
    <div class="online-item">
      <div class="item-header">
        <img src="/images/index/online/online-kefu-icon.png"/>
        <p>联系客服</p>
      </div>
      <div class="item-body">
        <div class="body-item title">
          工作时间（工作日）
          <p>{{ this.$store.state.user.systemInfo.workStartTime }}-{{
              this.$store.state.user.systemInfo.workEndTime
            }}</p>
        </div>
      </div>
    </div>
    <div class="online-item">
      <div class="item-header">
        <img src="/images/index/online/online-phone-icon.png"/>
        <p>咨询电话</p>
      </div>
      <div class="item-body">
        <div class="body-item">
          {{ this.$store.state.user.systemInfo.customerServicePhone }}
        </div>
      </div>
    </div>
    <div class="online-item">
      <!-- <div class="item-header">
        <img src="/images/index/online/online-app-icon.png"/>
        <p>APP下载</p>
      </div> -->

      <div class="item-body">
        <!-- <div class="qr-item">
          <img :src="$store.state.user.systemInfo.appQrCode"/>
          <p>安卓手机</p>
        </div> -->
        <div class="btn-item common-problem">
          <p><a :href="`/news/news-list?type=${newsCategory.categoryId}`" style="color: #000000">常见问题</a></p>
        </div>
        <div class="btn-item feedback" @click="$router.push({path: '/feedback'})">
          <p>意见反馈</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { newsApi } from '@/api/news';

export default {
  name: 'FloatCustomerService',
  components: {},
  filters: {},
  props: {},
  data() {
    return {
      newsCategory: {}
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    type() {
      return process.env.TYPE;
    }
  },
  watch: {},
  created() {
  },
  mounted() {
    window.onscroll = function () {
      const onlineService = document.getElementById('onlineService');
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      const nTarget = scrollTop + 150;
      if (onlineService) {
        move(onlineService, parseInt(nTarget));
      }
    };

    let oMoveTimer = null;

    function move(oDom, nTarget) {
      clearInterval(oMoveTimer);
      oMoveTimer = setInterval(function () {
        let speed = (nTarget - oDom.offsetTop) / 6;
        speed = speed > 0 ? Math.ceil(speed) : Math.floor(speed);
        if (oDom.offsetTop === nTarget) {
          clearInterval(oMoveTimer);
          return;
        }
        oDom.style.top = oDom.offsetTop + speed + 'px';
      }, 30);
    }

    this.getSubTypeList();
  },
  methods: {
    // 跳转PC页面
    openPc(path, query) {
      this.$pcRouter.push({ path, query });
    },
    async getSubTypeList() {
      try {
        const result = await newsApi.getAllTypeList({ categoryType: 200 });
        const data = result.data;
        this.newsCategory = data[data.length - 1];
      } catch (e) {
        console.log(e);
        this.$smartSentry.captureException(e);
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import "float-customer-service.less";
</style>
