/*
 * @Description:
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-23 16:31:32
 * @LastEditors: hanyu
 * @LastEditTime: 2020-11-12 10:46:17
 */

import Vue from 'vue';
import Axios from 'axios';
import cookie from '@/lib/cookie';
import { Message, Spin } from 'view-design';
import { ACCOUNT_SHOW_TYPE } from '@/constant/account';
import { getRandomChars } from '@/lib/utils.js';
import md5 from 'js-md5';

// 是否nuxt服务端请求
const isServer = Vue.prototype.$isServer;
// console.log('是否服务端请求：', isServer)

const axios = Axios.create({
  baseURL: process.env.baseUrl,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
});

let first = true; // 防抖处理
// 添加请求拦截器
axios.interceptors.request.use(
  // 在发送请求之前做些什么
  (config) => {
    if (!isServer) {
      if (cookie.getToken()) {
        config.headers['x-access-token'] = cookie.getToken();

        // 根据是否有专业类目信息判断是否需要完善个人信息
        // console.log('首页拿到的类目数据为：', JSON.stringify($nuxt.$store.getters['user/getCategoryObj']));
        if ($nuxt.$store.getters['user/getCategoryObj'] && $nuxt.$store.getters['user/getCategoryObj'].categoryId) {
          config.headers['x-category-id'] = $nuxt.$store.getters['user/getCategoryObj'].categoryId;
        } else if (!config.url.includes('/pc/user/info')) { // 非获取用户信息接口，走此逻辑
          if (first === true) {
            first = false;
            // 首页不再自动跳转个人中心了
            // 没有类目信息，自动提示并跳转个人信息完善
            // Message.error('未完善个人信息，请先完善信息');
            // setTimeout(() => {
            //   $nuxt.$pcRouter.push({ path: 'learn-center/my-info' });
            // }, 1000);
            delete config.headers['x-category-id'];
          }
          setTimeout(() => {
            first = true
          }, 1000);
        }
      }
      // const geoInfo = getGeo();
      // if (geoInfo) {
      //   config.headers['user-geo'] = geoInfo.userGeo;
      //   config.headers['user-location'] = encodeURI(geoInfo.userLocation);
      //   config.headers['user-identity'] = geoInfo.userIdentity;
      // }
    }
    // API签名校验
    const randomChars = getRandomChars(8);
    const timeStamp = new Date().getTime();
    const signStr = `${randomChars}${timeStamp}${randomChars}${process.env.SECRET_KEY}`;
    config.headers['x-access-nonce'] = randomChars;
    config.headers['x-access-time'] = timeStamp;
    config.headers['x-access-sign'] = md5(signStr);

    return config;
  },
  (error) => {
    if (!isServer) {
      Spin.hide();
    }
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  (res) => {
    const { data } = res;
    console.log(res);
    if (data.code !== 1) {
      if (!isServer) {
        if (data.code === 1005 || data.code === 1003) {
          // 清除cookie中的token和localStorage中的用户信息
          $nuxt.$store.commit('user/clearUserInfo');
          // 不在首页或登录页 显示登录弹窗
          const currentRoute = $nuxt.$router.currentRoute;
          if (currentRoute.name != 'index' && currentRoute.name != 'login') {
            const showAccountModal = $nuxt.$store.state.user.showAccountModal;
            if (!showAccountModal) {
              const params = {
                isShow: true,
                showType: ACCOUNT_SHOW_TYPE.LOGIN_PASS.value
              };
              $nuxt.$store.commit('user/changeAccountModal', params);
            }
          }
          Message.error('未登录或登录失效，请登录');
          const isH5 = currentRoute.path.includes('/h5/');
          if (isH5) {
            $nuxt.$router.replace({ path: '/h5/' });
          }
        } else {
          Message.error(data.msg);
          Spin.hide();
        }

        // else if (data.code === 14011) { // 重置密码失败
        //   return Promise.reject(res);
        // }
      }
      console.error(data);
      return Promise.reject(res);
    }
    return data;
  },
  (error) => {
    // 对响应错误做点什么
    if (!isServer) {
      Spin.hide();
      Message.error('服务内部错误');
    }
    return Promise.reject(error);
  }
);

export const postAxios = (url, data) => {
  return axios.post(url, data);
};
export const getAxios = (url, data) => {
  return axios.get(url, {
    params: data
  });
};

// 根据code获取微信信息，
// 因为此接口携带token请求时，会发送两次请求，
// 所以封装了这个方法，不携带token
export const getWxInfoBuyCode = (code) => {
  return new Promise((resolve, reject) => {
    Spin.show();
    axios.get(process.env.RM_URL + '/app/user/third/weChat/wap/userInfo/' + code).then((res) => {
      if (res.code === 1) {
        resolve(res.data);
      } else {
        reject(res);
      }
    }).catch((e) => {
      reject(e);
    }).finally(() => {
      Spin.hide();
    });
  });
};
