/**
 * 获取PC URL
 * @param url
 * @returns {*}
 */
export const getPcUrl = (url) => {
  const newPcUrl = {
    url: '',
    local: false
  };
  // 对应的新系统页面
  switch (url) {
    case 'learn-center':
      newPcUrl.url = 'center/user-center';
      break;
    case 'learn-center/my-info':
      newPcUrl.url = 'center/my-info';
      break;
    case 'learn-center/sign':
      newPcUrl.url = 'center/sign';
      // newPcUrl.url = 'center/my-info';
      break;
    case 'learn-center/my-course':
      newPcUrl.url = 'center/my-course';
      // newPcUrl.url = 'center/my-info';
      break;
    case 'my-order':
      newPcUrl.url = 'center/user-center';
      break;
    case 'video-play':
      newPcUrl.url = 'video-play';
      break;
    case 'live-play':
      newPcUrl.url = 'live-play';
      break;
    case 'feed-back':
      newPcUrl.url = 'center/feed-back';
      break;
    default :
      newPcUrl.url = url;
      break;
  }
  return newPcUrl;
};
