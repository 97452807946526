/*
  * @Description:
 * @Author: hanyu
 * @Date: 2020-05-23 11:51:03
 * @LastEditTime: 2020-09-26 11:38:05
 * @LastEditors: 李云飞
 */
// import {commonApi} from '@/api/module/common'

import { commonApi } from '@/api/common.js';

export const strict = false

export const state = () => ({
  majorList: [],
  defaultTab: 0// 默认 顶部 tab  0 -》首页
});
export const getters = {
};
export const mutations = {
  // 设置专业树信息
  SET_MAJOR_LIST (state, data) {
    state.majorList = data;
  },
  // 设置id
  setTabId (state, id) {
    state.defaultTab = id;
  }
};
export const actions = {
  // nuxt 初始化渲染获得网站基本信息
  async nuxtServerInit ({ commit }, { $sentry, app }) {
    // const result = await commonApi.getMajorList()
    // commit('SET_MAJOR_LIST', result.data)
  }
};
