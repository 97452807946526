<template>
  <div class="root">
    <nuxt />
  </div>
</template>
<script>
export default {
  name: 'H5Layout',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {};
  },
  computed: {},
  watch: {},
  filters: {},
  created () {
  },
  mounted () {
  },
  beforeCreate () {
  },
  beforeMount () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  activated () {
  },
  methods: {}
};
</script>
<style lang="less" scoped>

</style>
