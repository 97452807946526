<!--
 * @Description: 微信登录组件
 * @Author: hanyu
 * @Date: 2020-11-11 17:04:16
 * @LastEditTime: 2020-12-30 10:10:10
 * @LastEditors: hanyu
-->
<template>
  <div>
    <Modal v-model="show"
           footer-hide>
      <div id="login-con">
      </div>
    </Modal>
    <BindPhone ref="bindPhone" @success="$emit('success')" />
  </div>
</template>

<script>
import BindPhone from './components/bind-phone';

export default {
  components: { BindPhone },
  // 接收参数
  props: {},
  data () {
    // 这里存放数据
    return {
      show: false
    };
  },
  mounted () {
  },
  // 方法集合
  methods: {
    //  显示弹窗
    showModal () {
      const self = this;
      this.show = true;
      const host = window.location.origin;
      setTimeout(() => {
        const obj = new WxLogin({
          self_redirect: true,
          id: 'login-con',
          // appid: process.env.VUE_APP_WX_APPID,
          scope: 'snsapi_login',
          redirect_uri: encodeURIComponent(
            host + '/wx-login/middle-page'
          ),
          state: '123',
          style: 'black'
        });
        document.getElementById('login-con')
          .getElementsByTagName('iframe')[0].onload = async () => {
            const iframe = document
              .getElementById('login-con')
              .getElementsByTagName('iframe')[0];
            if (!iframe.contentDocument) {
              return;
            }
            const url = iframe.contentDocument.URL;
            if (url.includes('wx-login/middle-page')) {
              self.$Spin.show();
              const code = self.getUrlKey(url, 'code');
              const loginRes = await self.$store.dispatch('user/checkWxLoginAndLogin', code);
              debugger;
              //  绑定手机号，返回页面
              if (loginRes.code == 1 && loginRes.data && loginRes.data.phone) {
                self.$Spin.hide();
                self.show = false;
                self.$emit('success');
              } else {
              // 未绑定手机号
                self.show = false;
                self.$refs.bindPhone.showModal(loginRes.data);
              }
            }
          };
      }, 100);
    },
    // 获取url 中的key
    getUrlKey (url, name) {
      return (
        decodeURIComponent(
          (new RegExp(
            '[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)'
          ).exec(url) || [, ''])[1].replace(/\+/g, '%20')
        ) || null
      );
    }
  }
};
</script>
<style lang='less' scoped>
#login-con {
  text-align: center;
}
</style>
