<!--
 * @Description: 通用顶部
 * @Author: lihaifan
 * @Date: 2021/6/16 16:33
-->
<template>
  <!--<div class="header-bar">
    <div class="bar-container">
      <div class="bar-fl">
        <img src="/images/index/header-phone-icon.png">
        <p>客服电话：400-111-222</p>
      </div>
      <div class="bar-fr">
        <p>工作时间：7*24小时</p>
      </div>
    </div>
  </div>-->
</template>

<script>

export default {
  components: {},
  filters: {},
  props: {},
  data () {
    return {};
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
  },
  methods: {}
};
</script>
<style lang='less' scoped>
.header-bar {
  background: #f7f7f7;

  .bar-container {
    width: 1200px;
    margin: 0 auto;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 12px;
      font-weight: 400;
      color: #1c2634;
    }

    .bar-fl {
      display: flex;
      align-items: center;
      >img {
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }
    }

    .bar-fr {
      display: flex;
      align-items: center;
    }
  }
}
</style>
