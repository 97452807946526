<!--
 * @Description: 密码登录
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-26 08:44:38
 * @LastEditors: 马明昌 m17319036829@163.com
 * @LastEditTime: 2024-09-25 11:39:48
-->
<template>
  <div>
    <div class="form">
      <div class="form-row" :class="phoneError?'error':''">
        <img src="/images/index/input-icon/input-phone-icon.png"/>
        <div class="input-content">
          <Input
            v-model.trim="formData.phone"
            placeholder="请输入手机号码"
            maxlength="11"
          />
        </div>
      </div>
      <div class="validate-msg">{{ phoneError }}</div>
      <div class="form-row" :class="pwdError?'error':''">
        <img src="/images/index/input-icon/input-pwd-icon.png"/>
        <div class="input-content">
          <Input
            v-model.trim="formData.password"
            type="text"
            :style="{'webkit-text-security' : passHide ? 'disc' : 'unset','-moz-text-security' : passHide ? 'disc' : 'unset','text-security' : passHide ? 'disc' : 'unset'}"
            placeholder="请输入您的密码"
          />
        </div>
        <div
          class="toggle-pass"
          :class="{show: !passHide}"
          @click="passHide = !passHide"
        />
        <!--        <div class="pwd-tips">-->
        <!--          老学员密码为身份证后6位-->
        <!--        </div>-->
      </div>
      <div class="validate-msg">
        {{ pwdError }}
      </div>
      <div class="form-row">
        <img src="/images/index/input-icon/input-sms-icon.png"/>
        <div class="input-content">
          <Input
            v-model.trim="captchaData.captchaCode"
            placeholder="请输入验证码"
            maxlength="6"
          />
        </div>
        <div class="get-code" @click="getCaptcha">
          <img :src="captchaCodeImage"/>
        </div>
      </div>
      <div
        class="send-button"
        @click="loginBuyPass"
      >
        登 录
      </div>
    </div>

    <Spin
      v-if="isLoading"
      fix
    >
      <Icon
        type="ios-loading"
        size="36"
        class="demo-spin-icon-load"
      />
    </Spin>
  </div>
</template>

<script>
import { DEVICE_TYPE_ENUM, TERMINAL_APP_TYPE_ENUM, USER_REGISTER_TYPE_ENUM } from '@/constant/account';
import { getGeo } from '@/lib/geo';
import { commonApi } from '@/api/common';
import { accountApi } from '@/api/account';
import { regular } from '@/lib/regular';

export default {
  name: 'AccountLoginPass',
  components: {},
  filters: {},
  props: {},
  data() {
    return {
      USER_REGISTER_TYPE_ENUM,
      passHide: true,
      formData: {
        phone: null,
        password: null,
        deviceType: DEVICE_TYPE_ENUM.PC.value,
        terminalAppType: TERMINAL_APP_TYPE_ENUM.USER_PC.value
      },
      captchaData: {
        captchaCode: null,
        captchaUuid: null
      },
      isLoading: false,
      phoneError: '',
      pwdError: '',
      captchaCodeImage: null
    };
  },
  computed: {},
  watch: {
    'formData.phone'(val) {
      this.phoneError = '';
      if (!this.formData.phone) {
        this.phoneError = '请输入手机号码';
        return;
      }
      if (!regular.phone.test(this.formData.phone)) {
        this.phoneError = '手机号码不正确';
      }
    },
    'formData.password'(val) {
      this.pwdError = '';
      if (!this.formData.password) {
        this.pwdError = '请输入密码';
      }
    },
    // 监听是否密码
    passHide: {
      handler(val) {
        this.initInputPassword();
      },
      deep: true
    }

  },
  created() {
  },
  mounted() {
    // 初始化隐藏输入内容
    this.initInputPassword();
    this.getCaptcha();
  },
  beforeDestroy() {
    this.unListenEnterKeyDown();
  },
  methods: {
    // 发送验证码
    async getCaptcha() {
      if (this.isLoading) {
        return;
      }
      try {
        this.isLoading = true;
        const res = await commonApi.getCaptcha();
        this.captchaCodeImage = res.data.captchaBase64Image;
        this.captchaData.captchaUuid = res.data.captchaUuid;
      } catch (err) {
        this.$smartSentry.captureException(err);
      } finally {
        this.isLoading = false;
      }
    },
    //
    initInputPassword() {
      const x = document.getElementsByTagName('input')[2]; // 第3个input
      const style = window.getComputedStyle(x);
      // console.log(style);

      if (style.webkitTextSecurity) {
        // Do nothing
      } else if (this.passHide) { // 是否隐藏密码
        x.setAttribute('type', 'password');
      } else {
        x.setAttribute('type', 'text');
      }
    },
    // 监听回车按下事件
    listenEnterKeyDown() {
      document.onkeydown = (e) => {
        const event = e || event;
        if (event.keyCode == 13) {
          event.preventDefault();
          this.loginBuyPass();
        }
      };
    },
    // 撤销监听回车按下事件
    unListenEnterKeyDown() {
      document.onkeydown = undefined;
    },

    // 点击登录
    async loginBuyPass() {
      try {
        this.isLoading = true;
        if (!this.formData.phone) {
          this.phoneError = '请输入手机号码';
          this.$Message.error('请输入手机号码');
          return;
        }
        if (!regular.phone.test(this.formData.phone)) {
          this.codeError = '手机号码不正确';
          this.$Message.error('手机号码不正确');
          return;
        }
        if (!this.formData.password) {
          this.pwdError = '请输入密码';
          this.$Message.error('请输入密码');
          return;
        }
        if (!this.captchaData.captchaCode) {
          this.$Message.error('请输入验证码');
          return;
        }
        // 增加 经纬度、终端类型、设备类型 by 李晓冬
        const geoInfo = getGeo();
        const geoArr = (geoInfo.userGeo || '').split(',') || ['', ''];
        const param = {
          ...this.formData,
          latitude: geoArr[0],
          longitude: geoArr[1],
          captcha: this.captchaData
        };
        const result = await accountApi.loginByPass(param);
        
        this.$emit('loginSuccess', result.data);
        this.$Message.success('登录成功');
      } catch (err) {
        this.isLoading = false;
        this.getCaptcha();
        this.$smartSentry.captureException(err);
      } finally {
        this.isLoading = false;
      }
    },
    // 重置参数
    resetParams() {
      Object.assign(this.$data, this.$options.data.call(this));
    }

  }
};
</script>
<style lang="less" scoped>
@import "./account.less";

.forget-pass {
  margin-top: 15px;
  text-align: right;

  a {
    color: #999;
  }
}

/deep/ .ivu-select-single .ivu-select-selection {
  line-height: 50px;
  height: 50px;
}

/deep/ .ivu-select-selection .ivu-select-selected-value {
  line-height: 50px;
  height: 50px;
}

/deep/ .ivu-select-selection .ivu-select-placeholder {
  line-height: 50px;
  height: 50px;
}
</style>
