/*
 * @Description: 集成iview
 * @Author: hanyu
 * @Date: 2020-05-09 14:29:14
 * @LastEditTime: 2020-12-14 11:18:30
 * @LastEditors: hanyu
 */
import Vue from 'vue';
import iView from 'view-design';

Vue.use(iView);
