/*
 * @Description: 课程枚举
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-24 10:13:17
 * @LastEditors: 李云飞
 * @LastEditTime: 2020-09-25 15:32:18
 */
// 售卖类型
export const COURSE_SALE_TYPE_ENUM =
    {
      COURSE_FRANCO: {
        value: 1,
        desc: '免费课程'
      },
      COURSE_CHARGE: {
        value: 2,
        desc: '收费课程'
      },
      COURSE_RECEIVE: {
        value: 3,
        desc: '领取课程'
      }
    };

// 课程属性
export const COURSE_ATTRIBUTE_ENUM =
    {
      PLAN: {
        value: 5,
        desc: '旗舰班'
      },
      CLASS: {
        value: 3,
        desc: '精品班'
      },
      OFFLINE: {
        value: 4,
        desc: '面授班'
      },
      VIDEO: {
        value: 1,
        desc: '视频课'
      },
      LIVE: {
        value: 2,
        desc: '直播课'
      },
      COURSE: {
        value: 6,
        desc: '专项课'
      }
    };

// 课程类型
export const COURSE_TYPE_ENUM =
    {
      BASIS: {
        value: 1,
        desc: '基础'
      },
      SPRING: {
        value: 2,
        desc: '冲刺'
      },
      PRACTICE: {
        value: 3,
        desc: '技能'
      },
      SKILL: {
        value: 4,
        desc: '应试'
      },
      INTENSIVE: {
        value: 5,
        desc: '解题'
      },
      BEGINNER: {
        value: 6,
        desc: '入门'
      },
      EXAM_BEFORE: {
        value: 7,
        desc: '考前提分'
      },
      EXAM_SECOND: {
        value: 8,
        desc: '二试突击'
      }
    };

// 直播状态
export const COURSE_LIVE_TYPE_ENUM =
    {
      NOT_START: {
        value: 0,
        desc: '未开始'
      },
      LIVING: {
        value: 1,
        desc: '直播中'
      },
      COMPLETE: {
        value: -1,
        desc: '已结束'
      }
    };

export const POWER_TYPE_ENUM =
    {
      PUBLIC: {
        value: 1,
        desc: '公开的'
      },
      USER: {
        value: 2,
        desc: '仅学员'
      }
    };

export const
  RESOURCE_ATTRIBUTE_ENUM =
        {
          VIDEO: {
            value: 1,
            desc: '视频资源'
          },
          LIVE: {
            value: 2,
            desc: '直播资源'
          }
        };

// 直播回放类型
export const LIVE_PLAYBACK_TYPE_ENUM = {
  LIVE_PLAYBACK: {
    value: 1,
    desc: '直播回放'
  },
  VIDEO: {
    value: 2,
    desc: '视频文件'
  }
};

export const
  COURSE_CATEGORY_ENUM =
        {
          ZHONG_YAO: {
            value: 1,
            desc: '中药'
          },
          XI_YAO: {
            value: 2,
            desc: '西药'
          },
          ZHONG_XI_YAO: {
            value: 3,
            desc: '中西药'
          }
        };

export default {
  COURSE_SALE_TYPE_ENUM,
  COURSE_ATTRIBUTE_ENUM,
  COURSE_TYPE_ENUM,
  COURSE_LIVE_TYPE_ENUM,
  POWER_TYPE_ENUM,
  RESOURCE_ATTRIBUTE_ENUM,
  LIVE_PLAYBACK_TYPE_ENUM,
  COURSE_CATEGORY_ENUM
};
