<!--
 * @Description: 首页 全部课程  等导航
 * @Author: hanyu
 * @Date: 2020-09-20 16:24:31
 * @LastEditTime: 2020-10-22 14:48:40
 * @LastEditors: hanyu
-->
<template>
  <div class="header-row">
    <div class="header-box">
      <div style="display: flex">
        <nuxt-link class="logo" :style="{backgroundImage:'url(/images/index/index-logo.png)'}" to="/"></nuxt-link>
        <!-- 主要菜单 -->
        <MainMenu></MainMenu>
      </div>
      <!-- 右侧用户信息 -->
      <UserOption></UserOption>
    </div>
  </div>
</template>

<script>
import MainMenu from '../main-menu';
import UserOption from '../user-option';
export default {
  name: 'MainHeader',
  components: { MainMenu, UserOption },
  filters: {},
  props: {},
  data () {
    return {

    };
  },
  computed: {
    type () {
      return process.env.TYPE;
    }
  },
  watch: {},
  created () {
  },
  mounted () {
  },
  methods: {
  }
};
</script>
<style lang='less' scoped>
.header-row {
  background: #ffffff;
  overflow: hidden;
  .header-box {
    width: 1200px;
    margin: 0 auto;
    padding: 21px 0;
    justify-content: space-between;
    display: flex;
    align-items: center;
    .logo {
      display: block;
      width: 377px;
      height: 63px;
      margin-right: 88px;
      background: url("/images/index/index-logo.png") center no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
