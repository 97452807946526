<template>
  <Modal v-model="showBrowserValidModal" width="500" footer-hide :mask-closable="false" :closable="false" :scrollable="false">
    <p slot="header" class="header">
      <span>友情提示</span>
    </p>
    <div class="content">
      <p>请使用Chrome、Edge、Safari浏览器打开本系统</p>
    </div>
  </Modal>
</template>
<script>

export default {
  name: 'BrowserValidModal',
  components: {},
  filters: {},
  mixins: [],
  props: {},
  data () {
    return {};
  },
  computed: {
    showBrowserValidModal () {
      return this.$store.state.browser.showBrowserValidModal;
    }
  },
  watch: {},
  created () {
  },
  mounted () {
  },
  beforeCreate () {
  },
  beforeMount () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  activated () {
  },
  methods: {}
};
</script>
<style lang="less" scoped>
.header {
  color: #1c2634;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.content {
  display: flex;
  flex-direction: column;

  /deep/ .des {
    height: 180px;
  }

  > p {
    margin-left: 46px;
    font-size: 18px;
    font-weight: 500;
    line-height: 34px;
  }

}
</style>
