/*
 * @Description:
 * @Author: hanyu
 * @Date: 2020-05-28 14:28:44
 * @LastEditTime: 2020-09-21 14:40:54
 * @LastEditors: hanyu
 */
// smart sentry
import * as Sentry from '@sentry/browser';
import Vue from 'vue';

const smartSentry = {
  install (Vue) {
    Vue.prototype.$smartSentry = {
      // 主动上报
      captureException: (error) => {
        console.error(error);
        Sentry.captureException(error);
      },
      setUser: (userInfo) => {
        Sentry.setUser(userInfo);
      },
      clearUser: () => {
        Sentry.setUser(null);
      }
    };
  }
};
Vue.use(smartSentry);
