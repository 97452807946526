<!--
 * @Description: 课程相关
 * @Author: hanyu
 * @Date: 2020-09-20 16:21:21
 * @LastEditTime: 2020-09-28 16:40:59
 * @LastEditors: hanyu
-->
<template>
  <div class="root">
    <!-- 最顶部 -->
    <!--    <HeaderBar />-->
    <!-- 首页、全部课程 等 、个人中心 -->
    <MainHeader />
    <!-- 浮动客服 -->
    <FloatCustomerService ref="floatCustomerService" />
    <!-- 登录弹窗 -->
    <Account ref="accountRef" />
    <!-- 浏览器校验弹窗 -->
    <BrowserValidModal></BrowserValidModal>
    <nuxt keep-alive :keep-alive-props="{ include: includeArr }" />
    <IndexFooter />
  </div>
</template>

<script>
import HeaderBar from '@/components/header-bar';
import MainHeader from '@/components/header/main-header';
import Account from '@/components/account';
import FloatCustomerService from '@/components/float-customer-service';
import IndexFooter from '@/components/index-footer';
import BrowserValidModal from '@/components/browser-valid-modal';
import buryPoint from '@/mixins/bury-point';

export default {
  name: 'CourseLayout',
  components: {
    HeaderBar, MainHeader, Account, FloatCustomerService, IndexFooter, BrowserValidModal
  },
  filters: {},
  mixins: [buryPoint],
  props: {},
  data () {
    return {
      includeArr: ['Teacher']
    };
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
  },
  beforeCreate () {
  },
  beforeMount () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  activated () {
  },
  methods: {}
};
</script>
<style lang='less' scoped>
</style>
