/**
 账号相关 - 用户登录、注册、忘记密码接口
 */
import { postAxios, getAxios } from '@/lib/http';

export const accountApi = {

  // LPA平台用户登录/注册 by listen
  loginLpa (data) {
    return postAxios('/pc/user/login/lpa', data);
  },
  // 登录-账号密码登录 @listen
  loginByPass (data) {
    return postAxios('/pc/account/login', data);
  },
  // 登录-短信验证码登录 @listen
  loginBySms (data) {
    return postAxios('/pc/account/sms/login', data);
  },
  // 用户注册 by listen
  register (data) {
    return postAxios('/pc/account/sms/reg', data);
  },
  // 退出登录 @listen
  logout () {
    return getAxios('/pc/account/logout');
  },
  // 重置密码-短信验证码 @listen
  resetPassword (data) {
    return postAxios('/pc/account/pwd/reset', data);
  },
  // 查询详细资料 by listen
  getUserDetail () {
    return getAxios('/pc/user/info');
  },
};
