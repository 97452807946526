import { Spin } from 'view-design';
import { getUrlKey } from '../lib/utils';
import { getWxInfoBuyCode } from '../lib/http';

export const state = () => ({
  wxUserKey: 'ynys_wxUserInfo',
  wxUserInfo: null // 微信的用户信息
});

export const getters = {
  getWxUserInfo (state) {
    if (state.wxUserInfo) {
      return state.wxUserInfo;
    } else {
      const wxUserInfo = localStorage.getItem(state.wxUserKey);
      state.wxUserInfo = wxUserInfo ? JSON.parse(wxUserInfo) : null;
      return state.wxUserInfo;
    }
  }
};

export const mutations = {
  // 设置微信的用户信息
  setWxUserInfo (state, wxUserInfo) {
    state.wxUserInfo = wxUserInfo;
    localStorage.setItem(state.wxUserKey, JSON.stringify(wxUserInfo));
  },
  // 清除用户的微信信息
  removeWxUserInfo (state) {
    state.wxUserInfo = null;
  }
};

export const actions = {
  // 微信授权
  wxAuthorization () {
    const hrefUrl = window.location.href;
    const urlNow = encodeURIComponent(hrefUrl);
    const appid = process.env.WX_APPID;
    const state = '123';
    const scope = 'snsapi_userinfo';
    const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${urlNow}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
    location.href = url;
  },
  // 根据code获取微信信息
  async getWxInfoByCode ({ commit }, code) {
    Spin.show();
    try {
      const result = await getWxInfoBuyCode(code);
      commit('setWxUserInfo', result);
      return result;
    } catch (err) {
      console.error(err);
      Spin.hide();
      return false;
    }
  },
  // 检查是否有 code ,如果有code 直接去请求微信信息，然后注册
  // needRegist 是否需要注册
  async checkWxCodeAndLogin ({ getters, dispatch }) {
    const code = getUrlKey('code');
    if (code) {
      if (getters.getWxUserInfo) {
        return getters.getWxUserInfo;
      }
      const wxUserInfo = await dispatch('getWxInfoByCode', code);
      return wxUserInfo;
    }
    dispatch('wxAuthorization');
  }
};
