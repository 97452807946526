/*
 * @Description: seo 相关
 * @Author: hanyu
 * @Date: 2021-03-02 14:53:17
 * @LastEditTime: 2021-03-02 16:09:09
 * @LastEditors: hanyu
 */
import {
    seoApi
} from '@/api/seo.js'

export const state = () => ({
    headerDetail: null
});
export const actions = {
    // 获取某个seo
    getSeoDetail({}, pageId) {
        return {};
    }
}